import { useEffect, useState, useMemo, Fragment } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useIonToast, useIonModal } from '@ionic/react'
import { formatDate } from 'puffy-core/date'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import Toast from '../utils/Toast'
import { useLogFeatureInfo } from '../utils/session'
import HistoryGraph from './HistoryGraph'
import DateInfo from './DateInfo'
import FormDatePicker from './FormDatePicker'
import UpgradePopup from './UpgradePopup'
import { SETTINGS_UPGRADE_PLAN_PATH } from '../path'

const TEN_MIN_IN_MS = 10*60*1000
const ONE_DAY_IN_MS = 24*60*60*1000
const TWO_DAYS_IN_MS = 2*ONE_DAY_IN_MS
const THREE_DAYS_IN_MS = 3*ONE_DAY_IN_MS
const SEVEN_DAYS_IN_MS = 7*ONE_DAY_IN_MS
const HEIGHT_DAYS_IN_MS = 8*ONE_DAY_IN_MS
const ONE_MONTH_IN_MS = 30*ONE_DAY_IN_MS
const TWO_DAYS_SLUG = '2days'
const SEVEN_DAYS_SLUG = '7days'
const ONE_MONTH_SLUG = '1month'
const CUSTOM_SLUG = 'custom'

const GET_TOWER_DATA = gql`
query get_latest_data($tower_id: ID!, $from:String!, $to:String!, $include_baseline:Boolean, $sampling_rate:ReadingSamplingRateEnum) {
	tower_readings (
		where: { 
			tower: { id:$tower_id } 
			date: { from:$from to:$to } 
		} 
		include_baseline:$include_baseline
		sampling_rate: $sampling_rate
		limit:2000) {
		data {
			id
			date_utc
			deltat_1
			temp_1
			tempdiff_h10_h1_avg
			ws_2
			ws_10
			wd_10_label
			wd_cv2_label
			windgust_2
			solardown_10min
			tb_rainfall_tot
			rain_acc
			rain_prev_24h
			hazard_status
			hazard
			baseline
			nowcast
			humidity
		} 
	}
}`

const GraphBox = styled.div`
	& .graph-container {
		margin-bottom: 50px;
	}

	@keyframes refresh-icon-spin {
		from {
			transform:rotate(0deg);
		}
		to {
			transform:rotate(360deg);
		}
	}

	.refresh-spinner {
		animation-name: refresh-icon-spin;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	& .tz-switch {
		display:grid;
		grid-template-columns: 1fr 1fr;
		justify-content: flex-start;
		margin: 0px 50px 50px 50px;

		& .tz-details {
			font-style: italic;
			-webkit-user-select: none; /* Safari */
			-ms-user-select: none; /* IE 10 and IE 11 */
			user-select: none; /* Standard syntax */
		}

		& .spinning-btn {
			position: absolute;
			top: -10px;
		}

		& .refresh-button {
			display:flex;
			justify-content: flex-end;
		}
	}

	@media (max-width: 768px) {
		& .tz-switch {
			grid-template-columns: 4fr 5fr;
			margin: 0px 10px 50px 10px;
		}
	}
`

const PeriodSelector = styled.div`
	width: 100%;
	display: grid;
	grid-template-rows: 45px 30px;
	grid-template-columns: 1fr 327px;
	grid-template-areas: 
		'void buttons'
		'void range';
	margin-right: 50px;

	& .period-container {
		grid-area: buttons;
		margin-bottom: 10px;

		& button {
			white-space: nowrap;
		}
	}

	& .date-range {
		grid-area: range;
		display: flex;
		justify-content: end;
		color: rgba(0, 0, 0, 0.6);
		font-size: 14px;
		font-style: italic;
		font-weight: 400;

		& div {
			margin-right: 64px;
		}
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr 289px;

		.period-container {
			margin-right: 21px;
		}

		.date-range {
			& div {
				margin-right: 26px;
			}
		}
	}
`

const convert_tz_to_utc = (date?:any, zone?:any) => {
	if (zone && date) {
		const d = DateTime.fromISO(date.toISOString().replace(/Z$/,''), { zone })
		return new Date(d.toUTC().toISO())
	} else
		return date
}

const remove_duplicate = (data:any) => {
	const dedup:any = {}
	const l = (data||[]).length
	for (let i=0;i<l;i++) {
		const d = data[i]
		dedup[d.date_utc] = d
	}

	return Object.values(dedup)
}

const GetTowerData = (towerId:any, include_baseline?:boolean, periodLength?:string, customStartDate?:any, customEndDate?:any, tz?:any) => {
	const [getTowerData, towerDataOp] = useLazyQuery(GET_TOWER_DATA, {
		notifyOnNetworkStatusChange: true
	})

	useEffect(() => {
		if (towerId) {
			const now = Date.now()
			const periodInDays = !periodLength || periodLength == TWO_DAYS_SLUG ? TWO_DAYS_IN_MS :
				periodLength == SEVEN_DAYS_SLUG ? SEVEN_DAYS_IN_MS :
				periodLength == ONE_MONTH_SLUG ? ONE_MONTH_IN_MS : TWO_DAYS_IN_MS

			const aFewDaysAgo = now - periodInDays
			const customRange = periodLength == CUSTOM_SLUG && customStartDate && customEndDate
			
			let from:any, to:any
			if (customRange) {
				from = convert_tz_to_utc(customStartDate, tz)
				to = convert_tz_to_utc(customEndDate, tz)
			} else {
				from = new Date(Math.floor(aFewDaysAgo/TEN_MIN_IN_MS)*TEN_MIN_IN_MS)
				to = new Date(Math.ceil(now/TEN_MIN_IN_MS)*TEN_MIN_IN_MS)
			} 
			const period_ms = to.getTime() - from.getTime()
			const sampling_rate = period_ms < THREE_DAYS_IN_MS ? 'TEN_MINUTE' 
				: period_ms < HEIGHT_DAYS_IN_MS ? 'ONE_HOUR' : 'TWO_HOURS'

			const variables = {
				tower_id: towerId,
				from: from.toISOString(),
				to: to.toISOString(),
				include_baseline,
				sampling_rate
			}
			getTowerData({ variables })
		}
	}, [towerId, getTowerData, include_baseline, periodLength, customStartDate, customEndDate, tz])

	return {
		...towerDataOp,
		data: remove_duplicate(towerDataOp.data?.tower_readings?.data)
	}
}

const truncTo2Dec = (nbr:any) => Number(nbr.toFixed(2))

export default function History({ ...props }) {

	const { towerId, tz, towerName, access_24h } = props
	const limited_access = !access_24h
	const history = useHistory()

	// This is a hack for dev and stackeholders to access more data
	const showHiddenFields = new URLSearchParams(window.location.search).get('extra') == 'true'
	const [customStartDate, setCustomStartDate] = useState<any>(null)
	const [customEndDate, setCustomEndDate] = useState<any>(null)
	const [periodLength, setPeriodLength] = useState(TWO_DAYS_SLUG)
	const { loading:loadingTowerData, data:towerData, error:towerDataError, refetch } = GetTowerData(towerId, showHiddenFields, periodLength, customStartDate, customEndDate, tz)
	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])
	const [refetching, setRefetching] = useState(false)
	const logFeat = useLogFeatureInfo()

	const resetCustomDates = () => {
		if (customStartDate || customEndDate) {
			setCustomStartDate(null)
			setCustomEndDate(null)
		}
	}

	const [show_upgrade_modal, dismiss_upgrade_modal] = useIonModal(UpgradePopup, {
		history,
		onCancel: () => {
			dismiss_upgrade_modal()
		},
		on_upgrade:() => {
			dismiss_upgrade_modal()
			history.push(SETTINGS_UPGRADE_PLAN_PATH)
		}
	})
	
	const handlePeriodChange = (event:any, newPeriodLength: string) => {
		newPeriodLength = newPeriodLength || periodLength
		if (limited_access) {
			if (newPeriodLength == TWO_DAYS_SLUG) {
				setPeriodLength(newPeriodLength)
				resetCustomDates()
			} else
				show_upgrade_modal()
		} else {
			if (newPeriodLength == CUSTOM_SLUG)
				showDatePickerModal()
			else {
				setPeriodLength(newPeriodLength)
				resetCustomDates()
			}
		}
	}

	const [showDatePickerModal, hideDatePickerModal] = useIonModal(FormDatePicker, {
		start: customStartDate,
		end: customEndDate,
		onCancel: () => {
			hideDatePickerModal()
		},
		onSelected: (range:any) => {
			const [start, end] = range || []
			if (start && end) {
				setCustomStartDate(start)
				setCustomEndDate(end)
				setPeriodLength(CUSTOM_SLUG)
			} else
				resetCustomDates()
			hideDatePickerModal()
		}
	})

	const graphData = useMemo(() => {
		if (towerData && towerData.length) {
			const format = 'dd/MM/yyyy HH:mm'
			const x = { 
				data: tz
					? towerData.map((d:any) => {
						const dt = DateTime.fromISO(d.date_utc).setZone(tz)
						return dt.toFormat(format)
					}) 
					: towerData.map((d:any) => formatDate(new Date(d.date_utc), { format })) 
			} 

			return {
				lastReadingTime: new Date(towerData.slice(-1)[0]?.date_utc).getTime(),
				hazard: {
					x,
					y: { label:'Hazardous Inversion Index (m/s)', data: towerData.map((d:any) => truncTo2Dec((d.hazard||0)*1)) }
				},
				baseline: {
					x,
					y: { label:'Baseline', data: towerData.map((d:any) => truncTo2Dec((d.baseline||0)*1)) }
				},
				nowcast: {
					x,
					y: { label:'Nowcast', data: towerData.map((d:any) => truncTo2Dec((d.nowcast||0)*1)) }
				},
				tempDiff: {
					x,
					y: { label:'Inversion (Vert Temp Diff) (°C)', data: towerData.map((d:any) => truncTo2Dec((d.tempdiff_h10_h1_avg||0)*1)) }
				},
				windSpeed2: {
					x,
					y: { label:'Wind Speed (2m) (km/h)', data: towerData.map((d:any) => truncTo2Dec((d.ws_2||0)*3.6)) }
				},
				windSpeed10: {
					x,
					y: { label:'Wind Speed (10m) (km/h)', data: towerData.map((d:any) => truncTo2Dec((d.ws_10||0)*3.6)) }
				},
				maxWindSpeed: {
					x,
					y: { label:'Max Wind Gust (2m) (km/h)', data: towerData.map((d:any) => truncTo2Dec((d.windgust_2||0)*3.6)) }
				},
				deltaT: {
					x,
					y: { label:'Delta T (°C)', data: towerData.map((d:any) => truncTo2Dec((d.deltat_1||0)*1)) }
				},
				temperature: {
					x,
					y: { label:'Temperature (°C)', data: towerData.map((d:any) => truncTo2Dec((d.temp_1||0)*1)) }
				},
				solarRadiation: {
					x,
					y: { label:'Solar Radiation (W/m2)', data: towerData.map((d:any) => truncTo2Dec((d.solardown_10min||0)*1)) }
				},
				rainfall: {
					x,
					y: { label:'Rainfall (mm)', data: towerData.map((d:any) => truncTo2Dec((d.tb_rainfall_tot||0)*1)) }
				},
				humidity: {
					x,
					y: { label:'Relative Humidity (%)', data: towerData.map((d:any) => truncTo2Dec((d.humidity||0)*1)) }
				}
			}
		} else
			{}
	}, [towerData, tz])

	useEffect(() => {
		setRefetching(false)
		logFeat({ title:'48_hours_graphs', content:towerName })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [towerId, towerName])

	useEffect(() => {
		if (towerDataError)
			toast.show(`Failed to load tower's data`, { error:true, closeText:'close' })
	}, [towerDataError, toast])

	const onRefresh = () => {
		setRefetching(true)
		refetch()
	}

	const is_tower_offline = (periodLength:any, lastReadingTime:any) => {
		const last_days = periodLength != CUSTOM_SLUG
		const offline = last_days && !lastReadingTime
		const obj:any = document.querySelector('.gradient-bg')
		if (obj?.style?.display !== undefined)
			obj.style.display = offline ? 'none' : ''
		return offline
	}

	return (<div style={{ marginTop:'10px' }}>{!refetching && loadingTowerData ?
		<div><CircularProgress/></div> : !towerId ? 
		<Typography color="text.secondary">No tower selected</Typography> : towerDataError ? null : is_tower_offline(periodLength,graphData?.lastReadingTime) ?
		<div style={{ display:'flex', paddingTop:'40px' }}>Tower offline</div> :
		<GraphBox>
			<DateInfo 
				tz={tz} 
				loading={loadingTowerData} 
				marginBottom={'20px'}
				lastReadingTime={graphData?.lastReadingTime}
				onRefresh={onRefresh} />
			<PeriodSelector>
				<ToggleButtonGroup
					color="primary"
					value={periodLength}
					exclusive
					size="small"
					onChange={handlePeriodChange}
					aria-label="Platform"
					className="period-container"
					>
					<ToggleButton value={TWO_DAYS_SLUG}>2 Days</ToggleButton>
					<ToggleButton value={SEVEN_DAYS_SLUG}>7 Days</ToggleButton>
					<ToggleButton value={ONE_MONTH_SLUG}>1 Month</ToggleButton>
					<ToggleButton value={CUSTOM_SLUG}>Custom</ToggleButton>
				</ToggleButtonGroup>
				{customStartDate && customEndDate ? 
				<div className="date-range">
					<div>
						<span>{formatDate(customStartDate, { format:'dd/MM/yyyy' })}</span>
						<span style={{ marginLeft:'6px', marginRight:'6px' }}>-</span>
						<span>{formatDate(customEndDate, { format:'dd/MM/yyyy' })}</span>
					</div>
				</div> : null}
			</PeriodSelector>
			<HistoryGraph {...graphData?.hazard} 
				showLegend={true}
				normalZone={{
					from: 0.2,
					// to: 100000000,
					label: 'Hazardous Inversion ABSENT', 
					smallScreenLabel: 'ABSENT', 
				}}
				warningZone={{ 
					from:0, 
					to:0.2, 
					label: 'Hazardous Inversion PRESENT', 
					smallScreenLabel: 'PRESENT', 
					threshold:0.2
				}}/>{showHiddenFields ? 
			<HistoryGraph {...graphData?.baseline}/> : null}{showHiddenFields ? 
			<HistoryGraph {...graphData?.nowcast} warningZone={{ from:0.51, to:100, label: 'Limit', threshold:0.51 }}/> : null}
			<HistoryGraph {...graphData?.tempDiff} 
				showLegend={true}
				warningZone={{ from:0, to:100, label: 'T° Inversion', smallScreenLabel: 'T° Inversion',  threshold:0 }}
				/>
			<HistoryGraph {...graphData?.windSpeed2}/>
			<HistoryGraph {...graphData?.maxWindSpeed}/>
			<HistoryGraph {...graphData?.windSpeed10}/>
			<HistoryGraph {...graphData?.deltaT}/>
			<HistoryGraph {...graphData?.temperature}/>
			<HistoryGraph {...graphData?.solarRadiation}/>{limited_access ? null : <Fragment>
			<HistoryGraph {...graphData?.rainfall}/>
			<HistoryGraph {...graphData?.humidity}/></Fragment>}
		</GraphBox>
	}</div>)
}



